<template lang='pug'>
	.header.d-flex.justify-content-between.align-items-center(:class='{"header-clubhouse" : isClubhouse}')
		b-btn.btn-mobile.btn-menu-mobile(variant='outline-primary' size='lg' v-b-modal.modalMenu)
			svg(xmlns="http://www.w3.org/2000/svg" width="24.385" height="17.234" viewBox="0 0 24.385 17.234")
				g(id="Group_3" data-name="Group 3" transform="translate(1 1)")
					path(id="line" d="M0,.5H22.385" transform="translate(0 14.734)" fill="none" :stroke="style.color" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2")
					path(id="line-2" data-name="line" d="M0,.5H22.385" transform="translate(0 7.117)" fill="none" :stroke="style.color" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2")
					path(id="line-3" data-name="line" d="M0,.5H22.385" transform="translate(0 -0.5)" fill="none" :stroke="style.color" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2")


		img.logo1(:src='style.logo')

		ul.ul-nav
			li.ul-nav-item(v-for='i in menu')
				a(v-if='i.href' target='_blank' :href='i.href') {{ i.title }}
				router-link(v-else-if='i.isLink' :to="{name: i.hash}" target='_blank') {{ i.title }}
				a(:href='`#${i.hash}`' v-else) {{ i.title }}

		b-btn.btn-login(variant='outline-primary' size='lg' v-b-modal.modalLogin v-if='isLanding') Войти в веб-кабинет
		b-btn.btn-login(variant='white' size='lg' v-b-modal.modalLogin v-if='isClubhouse')
			//-img.mr-1(src='/assets/img/star.svg' style='margin-top: -2px')
			| Войти

		b-btn.btn-mobile.btn-login-mobile(variant='outline-primary' size='lg' v-b-modal.modalLogin)
			svg(width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg")
				path(d="M11.6919 9.98047H8.30986C4.98408 9.98047 2.27783 12.6863 2.27783 16.0125V20H17.7239V16.0125C17.7239 12.6863 15.0177 9.98047 11.6919 9.98047ZM3.44971 18.8281V16.0125C3.44971 13.3324 5.63018 11.1523 8.30986 11.1523H11.6919C14.3716 11.1523 16.552 13.3324 16.552 16.0125V18.8281H3.44971Z" :fill="style.color")
				path(d="M10.0004 0C7.42852 0 5.33594 2.09258 5.33594 4.66445C5.33594 7.23633 7.42852 9.32891 10.0004 9.32891C12.5723 9.32891 14.6648 7.23633 14.6648 4.66445C14.6648 2.09258 12.5723 0 10.0004 0ZM10.0004 8.15703C8.07461 8.15703 6.50781 6.59023 6.50781 4.66445C6.50781 2.73867 8.07461 1.17187 10.0004 1.17187C11.9262 1.17187 13.493 2.73867 13.493 4.66445C13.493 6.59023 11.9262 8.15703 10.0004 8.15703Z" :fill="style.color")




		b-modal#modalLogin(ref='modal' content-class='bg-blue-dark text-white' hide-footer hide-header size='xs')
			.form-login.text-center
				.landing-h1(:class="{'text-white' : isClubhouse}") Войти в веб-кабинет

				a(:href='`${loginUrl}`')
					b-btn.mb-3.landing-btn(variant='outline-white' size='lg') как администратор
				a(:href='consumer_url' target='_blank')
					b-btn.mb-4.landing-btn(variant='outline-white' size='lg') как подписчик

				div Есть вопросы? Читайте&nbsp;
					router-link(:to="{name: 'faq'}" target='_blank')
						strong.text-white
							| FAQ

		b-modal#modalMenu(ref='modalMenu' content-class='bg-blue-dark text-white' hide-footer hide-header no-fade)
			.modal-menu-top.d-flex.justify-content-between(:class='{"modal-menu-top-clubhouse" : isClubhouse}')
				b-btn.btn-mobile.btn-menu-close-mobile(variant='outline-white' size='lg' @click='hideMenu')
					svg(width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg")
						path(d="M15.8924 17.0537L2.92881 4.09003C2.60354 3.76476 2.60354 3.23679 2.92881 2.91152C3.25408 2.58625 3.78205 2.58625 4.10732 2.91152L17.0709 15.8751C17.3962 16.2004 17.3962 16.7284 17.0709 17.0537C16.7457 17.3789 16.2177 17.3789 15.8924 17.0537Z" fill="#fff")
						path(d="M2.92877 15.8753L15.8924 2.91172C16.2177 2.58645 16.7456 2.58645 17.0709 2.91172C17.3962 3.23699 17.3962 3.76496 17.0709 4.09023L4.10728 17.0539C3.78201 17.3791 3.25404 17.3791 2.92877 17.0539C2.6035 16.7286 2.6035 16.2006 2.92877 15.8753Z" fill="#fff")

				img.logo1(src='/assets/img/current/logo-white.svg')

				b-btn.btn-mobile.btn-login-mobile(variant='outline-white' size='lg' v-b-modal.modalLogin)
					svg(width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg")
						path(d="M11.6919 9.98047H8.30986C4.98408 9.98047 2.27783 12.6863 2.27783 16.0125V20H17.7239V16.0125C17.7239 12.6863 15.0177 9.98047 11.6919 9.98047ZM3.44971 18.8281V16.0125C3.44971 13.3324 5.63018 11.1523 8.30986 11.1523H11.6919C14.3716 11.1523 16.552 13.3324 16.552 16.0125V18.8281H3.44971Z" fill="#fff")
						path(d="M10.0004 0C7.42852 0 5.33594 2.09258 5.33594 4.66445C5.33594 7.23633 7.42852 9.32891 10.0004 9.32891C12.5723 9.32891 14.6648 7.23633 14.6648 4.66445C14.6648 2.09258 12.5723 0 10.0004 0ZM10.0004 8.15703C8.07461 8.15703 6.50781 6.59023 6.50781 4.66445C6.50781 2.73867 8.07461 1.17187 10.0004 1.17187C11.9262 1.17187 13.493 2.73867 13.493 4.66445C13.493 6.59023 11.9262 8.15703 10.0004 8.15703Z" fill="#fff")
			ul.ul-nav
				li.ul-nav-item(v-for='i in menu')
					a.text-white(v-if='i.href' target='_blank' :href='i.href') {{ i.title }}
					router-link.text-white(v-else-if='i.isLink' :to="{name: i.hash}" target='_blank') {{ i.title }}
					a.text-white(:href='`#${i.hash}`' @click='go(i.hash, $event)' v-else) {{ i.title }}
			.text-center
				a(:href='botSupport' target='_blank')
					b-btn.landing-btn.landing-btn-outline-white(size='xl') @{{$t('contacts.support_tg_bot')}}

</template>
<style lang='scss'>
    @media (min-width: 768.01px) and (max-width: 1079px) {
    	.btn-login {
    		font-size: 12px !important;
    	}
    }

    @media (min-width: 1080px) and (max-width: 1280px) {
    	.btn-login {
    		font-size: 14px !important;
    	}
    }

	@media (max-width: 1024px) {
		body {
			--bSize: 40px;
		}
		#modalMenu {
			.btn-menu-close-mobile,
			.btn-login-mobile {
				border-color: rgba(255, 255, 255, .5);
				&:hover {
					svg path {
						fill: var(--lColor0);
					}
				}
			}

			.modal-menu-top-clubhouse {
				.btn-menu-close-mobile,
				.btn-login-mobile {
					&:hover {
						svg path {
							fill: var(--lColor1);
						}
					}
				}
			}
		}
	}

    #modalMenu {
		width: 100%;

		.modal-dialog {
			margin: 0;
			max-width: 100%;
			width: 100%;
		}

		.modal-body {
			padding: 20px 100px 86px;
        }

        .modal-menu-top {
            height: 40px;
        }

		.ul-nav {
			margin-top: 100px;
			margin-bottom: 106px;
		}

		.ul-nav-item {
			display: block;
			font-size: 22px;
			text-align: center;
			margin-bottom: 42px;
			margin-right: 0;

			&:last-child {
				margin-bottom: 0;
			}
		}
    }

    #modalLogin {
        .landing-h1 {
            font-size: 40px;
            margin-bottom: 20px;
        }

        .modal-body {
            padding: 20px 20px 40px;
        }

        .landing-btn {
            width: 250px;
        }
    }

	@media (max-width: 767.98px) {
		#modalMenu {
			.modal-body {
				padding-left: 15px;
				padding-right: 15px;
				padding-bottom: 77px;
			}

			.modal-menu-top {
				margin-bottom: 88px;
			}

			.ul-nav {
				margin-top: 88px;
				margin-bottom: 73px;
			}
        }

        #modalLogin {
            width: 100%;

            .landing-h1 {
                font-size: 26px;
            }

            .modal-dialog {
                margin: 0;
                max-width: 100%;
                width: 100%;
            }

            .modal-body {
                padding: 20px;
            }
        }
	}
</style>
<style lang="scss" scoped>
	a {
		&:hover {
			color: var(--lColor0);
		}
	}

	.logo1 {
		width: 204px;
	}
	.header {
		--pX: 58px;
		--pY: 38px;

		height: 56px;
		margin: var(--pY) var(--pX) 120px var(--pX);

		&.header-clubhouse {
			margin-bottom: 80px;
		}
	}

	.btn-lg {
		font-size: 18px;
		font-weight: normal;
	}

	.btn-login-mobile,
	.btn-menu-mobile {
		display: none;
	}

	@media (max-width: 1400px) {
		.header {
			--pX: 46px;
		}
	}

	@media (max-width: 1023.98px) {
		.header {
			--pX: 15px;
			--pY: 15px;
			margin-bottom: 50px;

			.btn-login,
			.ul-nav {
				display: none;
			}

			.btn-login-mobile,
			.btn-menu-mobile {
				border-color: rgba(var(--lColor0), .5);
			}

			.btn-menu-mobile {
				svg path {
					stroke: var(--lColor0);
				}
				&:hover {
					svg path {
						stroke: #fff;
					}
				}
			}
			.btn-login-mobile {
				svg path {
					fill: var(--lColor0);
				}
				&:hover {
					svg path {
						fill: #fff;
					}
				}
			}

			&.header-clubhouse {
				.btn-login-mobile,
				.btn-menu-mobile {
					border-color: rgba(var(--lColor1), .5) !important;
					color: rgba(var(--lColor1), .5) !important;

					&:hover {
						background: #fff;
					}
				}

				.btn-menu-mobile {
					svg path {
						stroke: var(--lColor1);
					}
				}
				.btn-login-mobile {
					svg path {
						fill: var(--lColor1);
					}
				}
			}
		}

		.btn-mobile {
			display: flex;
			justify-content: center;
			align-items: center;
			width: var(--bSize);
			height: var(--bSize);
			padding: 0;
			svg {
				width: 20px;
			}
		}
	}

	@media (min-width: 768.01px) and (max-width: 1200px) {
		.logo1 {
			width: 141px;
		}
	}

	@media (min-width: 768.01px) and (max-width: 1024px) {

	}

	@media (max-width: 767.98px) {
		.header {
			&.header-clubhouse {
				margin-bottom: 40px;
			}
		}
		.logo1 {
			width: 121px;
		}

		.ul-nav {
			.ul-nav-item {
				display: block;
			}
		}
	}
</style>

<script>
import {navList, navListClubhouse} from '@/constants/config'
import {bus} from '@/main'

const NAMES = ['landing', 'clubhouse'];

export default {
	name: 'Calculator',
	components: {

	},
	props: {
		name: {
			default	: NAMES[0],
			type 	: String
		}
	},
	data: () => ({
		consumer_url: bus.botPay,
	}),
	computed: {
		menu () {
			return this.isLanding ? navList : navListClubhouse
		},
		style () {
			return {
				logo	: `/assets/img/logo-${this.isLanding ? 'blue' : 'dark'}.svg`,
				color 	: this.isLanding ? '#fafafc' : '#664E27'
			}
		},

		isLanding () { return this.name == NAMES[0]; },
		isClubhouse () { return this.name == NAMES[1]; }
	},
	created () {

	},
	methods : {
		hideMenu () {
			this.$refs.modalMenu.hide();
		},
		go ( hash, $e ) {
			this.hideMenu();
			setTimeout( () => {
				document.getElementById(hash).scrollIntoView();
			}, 100)
		}
	},
	watch: {
		$route () {

		}
	}
}
</script>
