<template lang='pug'>
	#howitworks.how-it-works.text-center
		.how
			//- .how-item
				//- video(loop autoplay muted playsinline preload)
					source(src="/assets/img/2.mp4" type="video/mp4")
			.how-item
				h3.landing-h2 Как это работает?

				.how-item-list
					.media.align-items-center(v-for='i in items')
						img(:src='i.img')
						.media-body.text-left(v-html='i.title')
					.how-item-list-arrow.how-item-list-arrow1
						//-img.how-item-list-arrow-img(src='/assets/img/arrow-down1.svg')
					.how-item-list-arrow.how-item-list-arrow2
						//-img.how-item-list-arrow-img(src='/assets/img/arrow-down2.svg')

				a(:href='loginUrl')
					b-btn.landing-btn(variant='primary' size='xl') Подключить Paywall
</template>
<style lang='scss'>
	@media (max-width: 1023.98px) {
		.how-item-list {
			br {
				display: none;
			}
		}
	}
</style>
<style lang="scss" scoped>
	@import '~@/assets/css/sass/_mixins.scss';
	.how-it-works {
		padding: 130px 0;
		position: relative;
	}
	.how {
		display: inline-flex;
		justify-content: center;
		align-items: center;
		width: 1330px;

		.how-item {
			&:first-child {
				// background: url('/assets/img/bg-video.png') no-repeat;
				background-size: contain;
				background-position: center;
			}
		}
		video {
			border-radius: 50px;
			box-shadow: 0px 11px 26px 5px rgba(64, 153, 247, 0.12);
			width: 372px;
			margin: 0 109px 0 149px;
		}
	}

	.landing-h2 {
		font-size: 60px;
	}

	.how-item-list {
		margin: 93px 0 97px;
		position: relative;

		.media {
			margin-bottom: 50px;
		}

		.media-body {
			color: var(--lColor3);
			margin-left: 52px;
			font-size: 20px;
			font-weight: 300;
		}
	}

	.how-item-list-arrow1 {
		position: absolute;
		top: 57px;
        left: -55px;

        .how-item-list-arrow-img {
            width: 65px;
        }
	}

	.how-item-list-arrow2 {
		position: absolute;
		top: 215px;
        left: 66px;

        .how-item-list-arrow-img {
            width: 45px;
        }
	}

	@media (min-width: 1300.01px) and (max-width: 1400px) {
		.how-it-works {
			padding-top: 125px;
		}

		.how {
			width: 1290px;
		}

		.how-item-list {
			margin: 100px 0 110px;
		}
	}

	@media (max-width: 1300px) {
		.how-item-list {
			margin: 68px 0 62px;

			.media {
				img {
					width: 60px;
					height: 60px;
				}
			}

			.media-body {
				margin-left: 20px;
				font-size: 16px;
			}
		}
	}

	@media (min-width: 1024px) and (max-width: 1300px) {
		.how-it-works {
			padding: 100px 0;
		}

		.how {
			width: 993px;
			video {
				width: 265px;
				margin: 0 78px 0 106px;
			}
		}

		.landing-h2 {
			font-size: 40px;
        }

        .how-item-list-arrow1 {
            top: 42px;
            left: -50px;

            .how-item-list-arrow-img {
                width: 60px;
            }
        }

        .how-item-list-arrow2 {
            top: 172px;
            left: 45px;

            .how-item-list-arrow-img {
                width: 42px;
            }
        }
	}

	@media (max-width: 1023.98px) {
		.how-it-works {
			padding: 160px 0 82px;
		}

		.how {
			flex-direction: column;
			width: auto;
			max-width: 330px;
			margin-right: 3%;

			video {
				border-radius: 20px;
				width: 182px;
				margin: 0 53px 0 73px;
			}
		}

		.how-item-list {
			margin: 50px 0 40px;

			.media {
				margin-bottom: 20px;
			}

			.media-body {
				font-size: 14px;
			}
		}

		.landing-h2 {
			font-size: 26px;
			position: absolute;
			top: 93px;
			left: 50%;
			transform: translateX(-50%);
        }

        .how-item-list-arrow {
            display: none;
        }
	}

	@media (max-width: 767.98px) {
		.how-it-works {
			padding: 125px 10px 60px;
		}

		.landing-h2 {
			top: 60px;
			width: 100%;
		}
	}
</style>

<script>
	export default {
		name 	: 'Works',
		components: {
		},
		data: () => ({
			items : [
				{img: '/assets/img/icons/works1.svg', title: 'Подключите Paywall к приватному каналу <br/>и настройте стоимость подписки'},
				{img: '/assets/img/icons/works2.svg', title: 'Приглашайте аудиторию в приватный канал, <br/>а остальное мы сделаем за вас '},
				{img: '/assets/img/icons/works3.svg', title: 'Смотрите статистику и выводите доход <br/>удобным для вас способом'}
			],
		}),
		computed: {

		},
		methods : {

			playVideo () {
				// this.$refs.modalVideo.show();
				this.$nextTick( () => {
					this.$refs.video.play();

					if (this.$refs.video.requestFullscreen) {
						this.$refs.video.requestFullscreen();
					} else if (this.$refs.video.mozRequestFullScreen) { /* Firefox */
						this.$refs.video.mozRequestFullScreen();
					} else if (this.$refs.video.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
						this.$refs.video.webkitRequestFullscreen();
					} else if (this.$refs.video.msRequestFullscreen) { /* IE/Edge */
						this.$refs.video.msRequestFullscreen();
					}
				});
			}
		},

		created () {

		}
	}
</script>
