<template lang='pug'>
	.content
		.wrapper-content
			footer.land-footer(:class='{"land-footer-clubhouse" : isClubhouse}')
				.d-flex.justify-content-between.align-items-center
					img.logo1(:src='style.logo')
					ul.ul-nav
						li.ul-nav-item(v-for='i in menu')
							a.text-default(v-if='i.href' target='_blank' :href='i.href') {{ i.title }}
							router-link.text-default(v-else-if='i.isLink' :to="{name: i.hash}" target='_blank') {{ i.title }}
							a.text-default(:href='`#${i.hash}`' v-else) {{ i.title }}

					a(:href='botNews' target='_blank')
						b-btn.btn-tg-outline(variant='outline-primary' v-if='isLanding')
							svg.mr-2(width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg")
								path(d="M7.84753 11.651L7.51669 16.3043C7.99003 16.3043 8.19503 16.101 8.44086 15.8568L10.66 13.736L15.2584 17.1035C16.1017 17.5735 16.6959 17.326 16.9234 16.3277L19.9417 2.18434L19.9425 2.18351C20.21 0.936842 19.4917 0.449342 18.67 0.755175L0.92836 7.54768C-0.282474 8.01768 -0.264141 8.69268 0.722526 8.99851L5.25836 10.4093L15.7942 3.81684C16.29 3.48851 16.7409 3.67018 16.37 3.99851L7.84753 11.651Z" fill="#4099F7")
							| Telegram
						b-btn.btn-tg-outline(variant='white' v-if='isClubhouse')
							svg.mr-2(width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg")
								path(d="M7.84753 11.651L7.51669 16.3043C7.99003 16.3043 8.19503 16.101 8.44086 15.8568L10.66 13.736L15.2584 17.1035C16.1017 17.5735 16.6959 17.326 16.9234 16.3277L19.9417 2.18434L19.9425 2.18351C20.21 0.936842 19.4917 0.449342 18.67 0.755175L0.92836 7.54768C-0.282474 8.01768 -0.264141 8.69268 0.722526 8.99851L5.25836 10.4093L15.7942 3.81684C16.29 3.48851 16.7409 3.67018 16.37 3.99851L7.84753 11.651Z" fill="#664E27")
							| Telegram

				.footer-copyright
					span © Paywall.pw {{ date }}
					span.footer-separator |
					router-link(:to="{name: 'terms'}" target='_blank') {{ $t('h1.serviceRules') }}
					span.footer-separator |
					router-link(:to="{name: 'privacy'}" target='_blank') {{ $t('h1.servicePrivacy') }}
					span.footer-separator |
					router-link(:to="{name: 'sub_terms'}" target='_blank') {{ $t('h1.serviceAgree') }}
					span.footer-separator |
					router-link(:to="{name: 'payment_processing'}" target='_blank') {{ $t('h1.paymentProcessing') }}
					span.footer-separator |
					router-link(:to='{name: "agency_contract"}' target='_blank') {{ $t('h1.agencyContract') }}
					span.footer-separator |
					router-link(:to='{name: "tech"}' target='_blank') {{ $t('tech.title') }}
				pay-services.footer-pay-services
</template>
<style lang='scss'>
	.land-footer {
		max-width: 1370px;
		width: 85%;
		margin: 0 auto;

		padding: 198px 0 50px;
		position: relative;

		&.land-footer-clubhouse {
			width: 100%;
		}

		& > .d-flex {
			margin-bottom: 40px;
		}

		.btn-tg-outline {
			font-size: 18px;
			font-weight: normal;
			white-space: nowrap;
			padding: 0 25px 0 18px;
			height: 57px;

			svg {
				margin-bottom: 4px;
			}

			&.btn-outline-primary {
				&:hover {
					svg path {
						fill: #fff;
					}
				}
			}

			&.btn-white {
				&:hover {
					svg path {
						fill: var(--lColor2);
					}
				}
			}
		}

		.footer-copyright {
			color: var(--lColor4);
			font-size: 13px;
			font-weight: normal;
			text-align: center;

			a {
				color: var(--lColor4);
				&:hover {
					color: var(--lColor4);
					text-decoration: underline;
				}
			}

			.footer-separator {
				padding: 0 15px;
			}
		}

		.footer-pay-services {
			position: absolute;
			left: 0;
			bottom: 50px;
		}

		.logo1 {
			width: 163px;
		}

	}
	@media (min-width: 1024px) and (max-width: 4000px) {
		.footer-copyright {
			margin-left: 200px;
			margin-right: 200px;
		}
		.land-footer .footer-pay-services {
			bottom: 70px;
		}
	}

    @media (min-width: 1024px) and (max-width: 1400px) {
        .land-footer {
            padding-top: 166px;
        }
    }

	@media (min-width: 768.01px) and (max-width: 1500px) {
		.ul-nav-item {
			margin-right: 18px;
		}
	}

	@media (min-width: 768.01px) and (max-width: 1200px) {
		.land-footer {
			.logo1 {
				width: 123px;
			}
		}
	}

	@media (max-width: 1024px) {
		.land-footer {
			padding-bottom: 30px;
			width: 100%;

			& > .d-flex {
				flex-direction: column;
				margin-bottom: 30px;
			}

			.ul-nav {
				margin-bottom: 30px;
				.ul-nav-item {
					margin-right: 23px;
				}
			}

			.logo1 {
				width: 150px;
				margin-bottom: 30px;
			}

			.footer-copyright {
				display: flex;
				flex-direction: column;

				.footer-separator {
					display: none;
				}

				a {
					margin-top: 10px;
				}
			}

			.footer-pay-services {
				position: relative;
				left: 50%;
				transform: translateX(-50%);
				margin-top: 20px;
				bottom: auto;
			}
		}
    }

    @media (min-width: 1023.98px) {
        .land-footer {
            padding-top: 180px;
        }
    }

	@media (max-width: 767.98px) {
		.land-footer {
            padding-top: 138px;

			.ul-nav {
				margin-bottom: 0;

				.ul-nav-item {
					display: block;
					margin: 0 0 26px;
					text-align: center;
				}
			}
		}
	}
</style>

<script>
	import moment from 'moment'
	import {navList, navListClubhouse} from '@/constants/config'
	import PayServices from '@/components/Common/PayServices'
	const NAMES = ['landing', 'clubhouse'];

	export default {
		components: {
			PayServices
		},
		data: () => ({

		}),
		props: {
			name: {
				default	: NAMES[0],
				type 	: String
			}
		},
		computed: {
			date () {
				return moment().format('YYYY');
			},
			menu () {
				return this.isLanding ? navList : navListClubhouse
			},
			style () {
				return {
					logo	: `/assets/img/logo-${this.isLanding ? 'blue' : 'dark'}.svg`,
					color 	: this.isLanding ? '#fafafc' : '#664E27'
				}
			},

			isLanding () { return this.name == NAMES[0]; },
			isClubhouse () { return this.name == NAMES[1]; }
		}
	}
</script>
