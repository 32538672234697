<template lang='pug'>
	.examples-merchant-list-item
		img.examples-merchant-list-item-img(:src='item.img')
		.examples-merchant-list-item-title {{ item.title }}
		.examples-merchant-list-item-desc(v-html='item.desc')
</template>

<style lang="scss" scoped>
	.examples-merchant-list-item {
		--size: 410px;

		background: #fff;
		border-radius: 20px;
		box-shadow: 0px 32px 29px -12px rgba(64, 153, 247, 0.08);
		padding: 55px 33px 40px;
		text-align: center;
		width: var(--size);
		height: var(--size);
	}

	.examples-merchant-list-item-img {
		--isize: 150px;
		width: var(--isize);
		height: var(--isize);
		border-radius: 50%;
		margin-bottom: 50px;
	}

	.examples-merchant-list-item-title {
		color: #000;
		font-size: 20px;
		font-weight: bold;
		margin-bottom: 20px;
	}

	.examples-merchant-list-item-desc {
		color: var(--lColor3);
		font-size: 16px;
		font-weight: 300;
	}

    @media (min-width: 1200.01px) and (max-width: 1400px) {

    }

	@media (max-width: 1200px) {
        .examples-merchant-list-item {
            --size: 340px;
            padding: 45px 27px 33px;
        }

        .examples-merchant-list-item-img {
            --isize: 124px;
            margin-bottom: 40px;
        }

        .examples-merchant-list-item-title {
            font-size: 16px;
        }

        .examples-merchant-list-item-desc {
            font-size: 14px;
        }
    }
    @media (min-width: 1024px) and (max-width: 1200px) {

	}

	@media (max-width: 1023.98px) {

	}

	@media (min-width: 768.01px) and (max-width: 1023.98px) {

	}

	@media (max-width: 767.95px) {
        .examples-merchant-list-item {
            width: 100%;
        }
	}
</style>

<script>
export default {
	name: 'ExampleMerchant',
	components: {

	},
	props: {
		item 	: {
			type: Object,
			default: () => ({})
		},
	},
	data: () => ({

	}),
	computed: {

	},
	created () {

	},
	methods : {

	}
}
</script>
