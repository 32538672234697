<template lang='pug'>
	.need-help
		div
			h2.landing-h1 Нужна помощь?
			.landing-desc Пишите в поддержку, персональный
				br.d-lg-none
				|  менеджер поможет
				br.d-none.d-lg-block
				|  вам настроить бота.
		a(:href='botSupport' target='_blank')
			b-btn.landing-btn.landing-btn-outline-primary( size='xl') @{{$t('contacts.support_tg_bot')}}
</template>

<style lang="scss" scoped>
	@import '~@/assets/css/sass/_mixins.scss';

	.need-help {
		padding: 76px 95px;
		background: #fff;
		border-radius: 20px;
		box-shadow: 0px 30px 29px -8px rgba(64, 153, 247, 0.12);

		display: inline-flex;
		justify-content: space-between;
		align-items: center;

		width: 1370px;
	}

	@media (min-width: 1200.01px) and (max-width: 1400px) {
		.need-help {
			width: 1140px;
		}
	}

	@media (min-width: 1024px) and (max-width: 1200px) {
		.need-help {
			padding: 56px 80px;
			width: 960px;
		}
	}

	@media (max-width: 1023.98px) {
		.need-help {
			padding: 45px 30px;
			flex-direction: column;
			text-align: center;
			width: 468px;
		}

		.landing-desc {
			margin-bottom: 30px;
		}
	}

	@media (max-width: 767.98px) {
		.need-help {
			width: auto;
		}
	}
</style>

<script>
	import {ENV} from '@/api/index'

	export default {
		name 	: 'NeedHelp',
		components : {

		},
		data: () => ({

		}),
		computed: {

		},
		methods : {

		}
	}
</script>
