<template lang='pug'>
	#examples.examples.bg-gradient-blue
		.content
			h2.landing-h1.text-center Наши пользователи
			.landing-desc.text-center И другие {{ totalTitle }} контента работают с нами

			flickity.examples-merchant-list(ref="flickity" :options="flickityOptions" v-if='!isMobile')
				example(v-for='i in items' :key='i.title' :item='i')
			.examples-merchant-list(v-else)
				example(v-for='i in items' :key='i.title' :item='i')
</template>
<style lang='scss'>
	@media (max-width: 1023.98px) {
		.how-item-list {
			br {
				display: none;
			}
		}
	}
</style>
<style lang="scss" scoped>
	@import '~@/assets/css/sass/_mixins.scss';

	.examples {
		padding: 130px 0 0;
	}

	.landing-h1 {
		margin-bottom: 20px;
	}

	.examples-merchant-list {
		margin-top: 100px;
	}

	.examples-merchant-list-item {
		margin-right: 70px;
		margin-bottom: 32px;
	}

	@media (min-width: 1200.01px) and (max-width: 1400px) {
		.landing-h1 {
			margin-bottom: 25px;
		}
	}

	@media (min-width: 1024px) and (max-width: 1200px) {
		.examples {
			padding: 100px 0;
		}

		.landing-h1 {
			margin-bottom: 25px;
		}

		.examples-merchant-list {
			margin-top: 70px;
		}

		.examples-merchant-list-item {
			margin-right: 30px;
		}
	}

	@media (max-width: 1023.98px) {
		.examples {
			padding: 60px 0;
		}

		.examples-merchant-list-item {
			margin-right: 20px;
        }

        .examples-merchant-list {
			margin-top: 35px;
		}
	}

	@media (max-width: 767.98px) {
		.examples {
			padding: 60px 0;
        }

        .content {
            padding: 0 15px;
        }

		.examples-merchant-list-item {
			margin-right: 0;
			margin-bottom: 20px;
		}
	}
</style>

<script>
	import { mapGetters, mapActions } from 'vuex'
	import {SERVICE_STRING} from '@/utils/string'
	import CardExampleMerchant from '@/components/Landing/CardExampleMerchant'
	import Flickity from 'vue-flickity'

	export default {
		name 	: 'Examples',
		components: {
			'example'               : CardExampleMerchant,
			Flickity
		},
		data: () => ({
			flickityOptions : {
				wrapAround      : true,
				initialIndex	: 0,
				prevNextButtons	: false,
				pageDots		: false,
				groupCells 		: 1,
				// freeScroll      : true,
				tickerSpeed     : 1,
				selectedAttraction: 0.015,
				friction        : 0.25
			},
			items : [
				/*{img: '/assets/img/example1.png', title: 'RomanFinance: торговый чат', desc: 'Торговые рекомендации на Московской и Санкт-Петербургской бирже от Романа Романовича и Михаила Городилова.'},
				{img: '/assets/img/example2.png', title: 'Gurov Digilal Pro Max', desc: 'Закрытый блог Павла Гурова о маркетинге. Инсайдерская информация, которая не появляется в публичном доступе'},
				{img: '/assets/img/example3.png', title: 'Сын Баффета (Private)', desc: 'Закрытый клуб “Сын Баффета” на котором публикуется прибыльные идеи от создателей проекта.'},
				{img: '/assets/img/example1.png', title: 'RomanFinance: торговый чат1', desc: 'Торговые рекомендации на Московской и Санкт-Петербургской бирже от Романа Романовича и Михаила Городилова.'},
				{img: '/assets/img/example2.png', title: 'Gurov Digilal Pro Max1', desc: 'Закрытый блог Павла Гурова о маркетинге. Инсайдерская информация, которая не появляется в публичном доступе'},
				{img: '/assets/img/example3.png', title: 'Сын Баффета (Private)1', desc: 'Закрытый клуб “Сын Баффета” на котором публикуется прибыльные идеи от создателей проекта.'}*/
			],
		}),
		computed: {
			...mapGetters({
				total	: 'users/totalMerchants',
			}),

			totalTitle () {
				return this.total + ' ' + SERVICE_STRING.ru_ends(this.total, ['создатель', 'создателя', 'создателей'])
			}
		},
		methods : {
			...mapActions({
				get : 'users/totalMerchants'
			}),
			onResize() {
				this.initFL();
			},
			FLUpdate () {
				if ( !this.$refs.flickity ) return;
				let flickity = this.$refs.flickity.$flickity;

				if (this.isPaused) return;
				if (flickity.slides) {
					flickity.x = (flickity.x - this.flickityOptions.tickerSpeed) % flickity.slideableWidth;
					flickity.selectedIndex = flickity.dragEndRestingSelect();
					flickity.updateSelectedSlide();
					flickity.settle(flickity.x);
				}
				window.requestAnimationFrame(this.FLUpdate);
			},

			FLPause () {
				this.isPaused = true;
			},

			FLPlay () {
				if (this.isPaused) {
					this.isPaused = false;
					window.requestAnimationFrame(this.FLUpdate);
				}
			},

			initFL() {
				if ( !this.$refs.flickity ) return;

				const slideshowEl   = document.querySelector('.examples-merchant-list');
				let flickity        = this.$refs.flickity.$flickity;

				this.isPaused       = false;
				flickity.x = 0;
				slideshowEl.removeEventListener('mouseenter', this.FLPause)
				slideshowEl.addEventListener('mouseenter', this.FLPause, false);
				slideshowEl.removeEventListener('focusin', this.FLPause)
				slideshowEl.addEventListener('focusin', this.FLPause, false);
				slideshowEl.removeEventListener('mouseleave', this.FLPlay)
				slideshowEl.addEventListener('mouseleave', this.FLPlay, false);
				slideshowEl.removeEventListener('focusout', this.FLPlay)
				slideshowEl.addEventListener('focusout', this.FLPlay, false);

				flickity.on('dragStart', () => this.isPaused = true);
				this.FLUpdate();
			}
		},

		created () {
			this.get()
		},

		mounted () {
			this.$nextTick(() => {
				window.addEventListener('resize', this.onResize);
				this.initFL();
			})
		},

		beforeDestroy() {
			window.removeEventListener('resize', this.onResize);
		},
	}
</script>
